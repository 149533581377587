import React from "react"
import bannerStyles from "../styles/modules/banner.module.scss"
// import { useLocation } from "@reach/router" // this helps tracking the location
// import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import CookieConsent from "react-cookie-consent";
import "../styles/scss/banner.scss";



export default function Banner() {
  return (
    <section className={bannerStyles.banner}>
      {/* <h1 className={bannerStyles.bannerTitle}>
        This site uses cookies. 
      </h1> */}

      <CookieConsent  
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        cookieName="gatsby-gdpr-google-analytics">
        I use cookies not the chocolate type, just to track visits to my website and don't save any personal data.
      </CookieConsent>
    </section>
  )
}